<template>
  <div>
    <template v-if="config.portalUIConfiguration.enableNavigationSideBar">
      <v-navigation-drawer v-model="ui.showLeftSideBar" clipped fixed app
                           :width="config.portalUIConfiguration.navigationSideBarWidth"
                           :permanent="config.portalUIConfiguration.permanentNavigationSideBar">
        <component :is="config.sideBarNavigation.elementName" :componentWidth="config.portalUIConfiguration.navigationSideBarWidth"/>
      </v-navigation-drawer>
    </template>
    <template v-if="config.portalUIConfiguration.portalHeaderConfiguration.enableHeader">
      <v-app-bar flat clipped-left clipped-right app :height="config.portalUIConfiguration.portalHeaderConfiguration.headerHeight">
        <v-toolbar-title class="pl-1">
          <header-logo/>
        </v-toolbar-title>
        <v-spacer/>
          <header-title v-if="$vuetify.breakpoint.mdAndUp"/>
        <v-spacer/>
				<icon-button portal-icon-id="topmenu.quickSearch" v-shortkey.once="['ctrl', 's']" @shortkey.native="showQuickSearch" bottom @click="showQuickSearch" :tooltip="$t('tooltipQuickSearch')"/>
        <icon-button portal-icon-id="topmenu.showTileView" v-if="config.portalUIConfiguration.viewType === 'row'" bottom @click="setViewType('tile')" :tooltip="$t('tooltipShowThumbnailView')"/>
				<icon-button portal-icon-id="topmenu.showListView" v-if="config.portalUIConfiguration.viewType === 'tile'" bottom @click="setViewType('row')" :tooltip="$t('tooltipShowListView')"/>
        <icon-button portal-icon-id="topmenu.basket" v-if="basketEnabled" bottom :badge-value="assetCount" :tooltip="$t('tooltipShowBasket')" :to="{name:'Basket',params:{'portal':portal}}"/>
        <icon-button portal-icon-id="topmenu.logout" v-if="!anonymous" bottom :tooltip="$t('tooltipExitApplication')" @click="logout"/>
				<v-app-bar-nav-icon @click.stop="toggleLeftSideBar" :tooltip="$t('tooltipToggleLeftSidebar')">
					<template v-slot:default>
						<portal-icon portal-icon-id="topmenu.toggleLeftSidebar"></portal-icon>
					</template>
				</v-app-bar-nav-icon>
      </v-app-bar>
    </template>
    <v-main>
      <search-information-header/>
      <router-view/>
    </v-main>
    <template v-if="config.portalUIConfiguration.enableFooter">
      <basic-footer/>
    </template>
  </div>
</template>

<script>
  import {mapActions, mapGetters, mapState} from 'vuex'
  import AssetContainerNavigation from "../components/AssetContainerNavigation"
  import HeaderLogo from "../components/HeaderLogo"
  import IconButton from "../components/IconButton"
  import BasicFooter from "../components/BasicFooter"
  import AssetFilterSidebar from "../components/AssetFilterSidebar"
  import ActiveFilterPanel from "../components/ActiveFilterPanel"
  import SearchInformationHeader from "../components/SearchInformationHeader"
	import PortalIcon from "../components/PortalIcon"
  import HeaderTitle from "../components/HeaderTitle";

  export default {
    title () {
      return this.config.portalUIConfiguration.htmlTitle
    },
    components: {
      HeaderTitle,
      SearchInformationHeader,
      ActiveFilterPanel,
      AssetFilterSidebar,
      BasicFooter,
      IconButton,
      HeaderLogo,
      AssetContainerNavigation,
			PortalIcon
    },
    data() {
      return {
        // navigation: "AssetContainerNavigation",
        active: null
      }
    },

    methods: {
      ...mapActions({
        'mainLogout': 'main/logout'
      }),

      toggleLeftSideBar() {
        this.$store.commit('config/TOGGLE_LEFT_SIDEBAR')
      },
      showBasket() {
        this.$store.commit('basket/SHOW_BASKET', true)
      },
      setViewType(type) {
        this.$store.commit('config/SET_VIEW_TYPE', type)
      },
      logout() {
        this.mainLogout()
        this.$router.push({name: 'Login', params: {portal: this.$store.state.main.portal}})
      },
      toggleDarkMode() {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      },
      showQuickSearch() {
        this.$router.push({name: 'QuickSearch'})
      }
    },
    computed: {
      ...mapState({
        portal: state => state.main.portal,
        basketEnabled: state => state.config.server.basketConfiguration.enableBasket,
        ui: state => state.config.local.ui,
        config: state => state.config.server
      }),
      ...mapGetters({
        'assetCount': 'basket/assetCount',
        'anonymous': 'main/anonymous'
      })
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  /* Workaround for wrong dialog offset */
  .v-dialog {
    margin: 24px 0;
  }

  .v-dialog--fullscreen {
    margin: 0 0;
  }
</style>
