<template>
  <div v-bind:style="titleClass">{{$t('portalHeaderTitle')}}</div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: "HeaderTitle",

  computed: {
    ...mapState({
      config: state => state.config.server.portalUIConfiguration.portalHeaderConfiguration
    }),
    titleClass: function () {
        return {
          'color': this.config.portalHeaderTitleColor,
          'font-weight': this.config.portalHeaderTitleFontWeight,
          'font-size': this.config.portalHeaderTitleFontSize
        }
    }
  }
}
</script>

<style scoped>

</style>