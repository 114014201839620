<template>
  <v-list-item :class="itemClass" link>
    <v-icon :color="folderIconColor" class="pr-2" @click="itemClick">{{portalIcon(folderIcon)}}</v-icon>
    <v-list-item-content @click="itemClick">
      <v-list-item-title class="pointer">{{itemName}}</v-list-item-title>
    </v-list-item-content>
    <v-list-item-icon>
      <v-btn small text icon @click.stop="actionIconClick" v-if="showActionIcon">
        <v-icon :color="actionIconColor">{{portalIcon(actionIcon)}}</v-icon>
      </v-btn>
    </v-list-item-icon>
  </v-list-item>
</template>

<script>
  import portalIcons from "../portal-icons"
  import localApi from "../services/localApi"
  export default {
    name: "AssetContainerItem",
    props: {
      itemClass: {type: String, default: ''},
      itemName: {type: String, default: ''},
      folderIcon: {type: String, default: ''},
      folderIconColor: {type: String, default: ''},
      actionIcon: {type: String, default: ''},
      actionIconColor: {type: String, default: ''},
      showActionIcon:{type:Boolean,default: true}
    },
    methods: {
      portalIcon(iconName) {
        let mdiIconName = localApi.toCamelCase(iconName)
        return portalIcons.icons[mdiIconName]
      },
      itemClick() {
        this.$emit('itemClick')
      },
      actionIconClick() {
        this.$emit('actionClick')
      }
    }
  }
</script>

<style scoped>
  .pointer {
    cursor: pointer;
  }
</style>
